import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      options_simplify: {
        tolerance: 0.00001,
        highQuality: false,
      },
    }
  },

  computed: {
    own_parcelles() {
      return [
        this.$store.getters['parcelle/current_parcelle'],
        ...this.$store.getters['parcelle/parcelles_voisines'],
      ].filter((parcelle) => parcelle)
    },
    ...mapGetters({
      infos_parcelles: 'address/infos_parcelles',
    }),
  },
  methods: {
    getParcellePrincipalThroughCenterOfAllParcelles(own_parcelles) {
      if (!own_parcelles.length) return null
      let center = this.$turf.pointOnFeature(
        this.$turf.featureCollection(own_parcelles),
      )
      return own_parcelles.find((parcelle) =>
        this.$turf.booleanPointInPolygon(center, parcelle),
      )
    },
    resetParcelleVoisines(
      is_dash = false,
      parcelles_voisines = this.parcelles_voisines,
      withoutStore = false,
      source_layers_all_parcelles_selected = this
        .source_layers_all_parcelles_selected,
    ) {
      this.removeSourceAndLayers(...this.source_and_layers_parcelles_voisines)
      parcelles_voisines.forEach((parcelle) => {
        this.removeSourceAndLayers(
          ...this.concatSourceAndLayersWithRef(
            ...this.source_and_layers_parcelles_voisines,
            parcelle.properties.id,
          ),
        )
      })
      if (is_dash) {
        this.addParcellesSelected(
          parcelles_voisines,
          this.green_parcelle_selected,
          source_layers_all_parcelles_selected,
        )
      } else {
        if (!withoutStore) {
          this.$store.commit('parcelle/PARCELLES_VOISINES', [])
        } else {
          this.form.informations.parcelles_voisines = []
        }
      }
    },
    addParcellesAutour(parcelles_autour) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.white_parcelle_not_selected
      options.is_dash = false
      options.is_multi_geojson = true
      this.addPolygonsToMap(
        parcelles_autour,
        this.source_layers_selected_parcelles_autour,
        options,
      )
    },
    getParcelleWithBuffer(parcelle) {
      return this.$turf.buffer(parcelle, 0.0003)
    },
    addParcellesSelected(
      parcelles_selected,
      color = this.green_parcelle_selected,
      source_layers_all_parcelles_selected = this
        .source_layers_all_parcelles_selected,
    ) {
      parcelles_selected.forEach((parcelle) => {
        let options = this.copyObject(this.options_polygone)
        options.color = color
        this.addPolygonsToMap(
          parcelle,
          this.concatSourceAndLayersWithRef(
            ...source_layers_all_parcelles_selected,
            parcelle.properties.id,
          ),
          options,
        )
      })
    },
    getAndAddMakerslengthsWallsToMap(tabWall, old_markers) {
      old_markers.forEach((marker) => {
        marker.remove()
      })
      let current_markers = []
      tabWall.forEach((wall) => {
        // create a DOM element for the marker
        if (wall.sizeWall > 2) {
          var el = document.createElement('div')
          el.className = 'mapbox_marker_label'
          el.style.backgroundColor = 'white'
          el.innerHTML = wall.sizeWall + ' m'
          // add marker to map
          let marker = new this.$mapboxgl.Marker(el)
            .setLngLat(wall.middleWall)
            .addTo(this.map)
          current_markers.push(marker)
        }
      })
      return current_markers
    },
    getTailleMurs(geojson) {
      let tabWall = []
      geojson.geometry.coordinates.forEach((coordinate) => {
        try {
          let simplify_geojson = this.$turf.simplify(
            this.$turf.polygon(this.getCoordinates([coordinate])),
            this.options_simplify,
          )
          var simplify_geojson_line = this.$turf.polygonToLine(simplify_geojson)
          let geometry =
            simplify_geojson_line.type === 'Feature'
              ? simplify_geojson_line.geometry
              : simplify_geojson_line.features[0].geometry
          if (geometry.type === 'LineString') {
            geometry.coordinates = [geometry.coordinates]
          }
          geometry.coordinates.forEach((coordinate) => {
            let lines = this.splitArrayByTwoValues(coordinate)
            lines.forEach((line) => {
              var line_string = this.$turf.lineString(line)
              var sizeWall = this.roundDouble(
                this.$turf.length(line_string, this.option_length_line) * 1000,
              )
              var middleWall =
                this.$turf.center(line_string).geometry.coordinates
              tabWall.push({
                sizeWall: sizeWall,
                middleWall: middleWall,
              })
            })
          })
        } catch (e) {
          console.log('Invalid GeoJSON', e)
        }
      })
      return tabWall
    },
    splitArrayByTwoValues(array) {
      let i,
        new_array = [],
        chunk = 2
      for (i = 0; i < array.length - 1; i++) {
        new_array.push(array.slice(i, i + chunk))
      }
      return new_array
    },
    roundDouble(number) {
      return Math.round((number * 10) / 10)
    },
    getCenterParcelle(parcelle) {
      return this.$turf.pointOnFeature(this.$turf.buffer(parcelle, -0.0003))
        .geometry.coordinates
    },
    SumSurfaceMesure(all_parcelles) {
      let surface = 0
      all_parcelles.forEach((parcelle) => {
        surface += this.calculeArea(parcelle)
      })
      return surface
    },
    calculeArea(parcelle) {
      return this.$turf.area(parcelle)
    },
    SumSurfaceCadastrale(all_parcelles) {
      let surface = 0
      all_parcelles.forEach((parcelle) => {
        surface += this.getSurfaceCadastrale(parcelle)
      })
      return surface
    },
    getSurfaceCadastrale(parcelle) {
      let contenance = parcelle.properties.contenance
      let surface = contenance !== '' ? contenance : this.calculeArea(parcelle)
      return surface
    },
    checkSurfaceLibreValide(all_parcelles) {
      let percent = this.getPercentageChange(
        this.SumSurfaceCadastrale(all_parcelles),
        this.SumSurfaceMesure(all_parcelles),
      )
      return percent < 10
    },
    getPercentageChange(first_number, seconde_number) {
      var diff = first_number - seconde_number
      let divider =
        first_number > seconde_number ? first_number : seconde_number
      return Math.abs((diff / divider) * 100)
    },
    SumSurfaceLibre(all_parcelles) {
      let surface_libre = 0
      all_parcelles.forEach((parcelle) => {
        surface_libre += this.singleSurfaceLibre(parcelle)
      })
      return surface_libre
    },
    singleSurfaceLibre(parcelle) {
      let surface_parcelle =
        this.getSurfaceCadastrale(parcelle) !== 0
          ? this.getSurfaceCadastrale(parcelle)
          : this.calculeArea(parcelle)
      let surface_libre = surface_parcelle - this.sumSurfaceBatiments(parcelle)
      return surface_libre < 0 ? 0 : surface_libre
    },
    getBatimentsByRefParcelle(parcelle) {
      return this.infos_parcelles.find(
        (feature) => feature.parcelle.id === parcelle.id,
      ).batiments
    },
    sumSurfaceBatiments(parcelle) {
      let surface_batis = 0
      let batiments_intersect_current_parcelle =
        this.getIntersectBatimentsWithMultiParcelle(
          parcelle,
          this.getBatimentsByRefParcelle(parcelle).features,
        )
      batiments_intersect_current_parcelle.forEach((batiment) => {
        surface_batis += this.calculeArea(batiment)
      })
      return surface_batis
    },
    getFeaturesExist(features_autour, own_parcelles) {
      return features_autour.filter((feature) =>
        own_parcelles
          .filter((item) => item)
          .map((parcelle) => parcelle.properties.id)
          .includes(feature.parcelle.properties.id),
      )
    },
    sumNombreBatiments(all_parcelles) {
      return this.current_batiments.filter((bati) =>
        bati.properties.id_parcelles.some((id_parcelle) =>
          all_parcelles
            .map((parcelle) => parcelle.properties.id)
            .includes(id_parcelle),
        ),
      ).length
    },
    getLayersParcelleVoisines(parcelles_voisines) {
      return parcelles_voisines.map(
        (parcelle) =>
          this.source_and_layers_parcelles_voisines[1] +
          '-' +
          parcelle.properties.id,
      )
    },
    getLayersParcellesSelected(parcelles_selected) {
      return parcelles_selected.map(
        (parcelle) =>
          this.source_layers_all_parcelles_selected[1] +
          '-' +
          parcelle.properties.id,
      )
    },
    filterParcellesAutour() {
      let ids_parcelles = this.own_parcelles.map(
        (parcelle) => parcelle.properties.id,
      )
      this.map.setPaintProperty(
        this.source_layers_selected_parcelles_autour[1],
        'fill-opacity',
        [
          'match',
          ['get', 'id'],
          ids_parcelles.length ? ids_parcelles : [-1],
          0.1,
          this.options_polygone.opacity,
        ],
      )
    },
    addLayerGeoParcelles() {
      this.map.addSource(this.source_and_layers_geo_parcelles[0], {
        type: 'vector',
        tiles: [process.env.VUE_APP_CADASTRE_ETALAB_GEO_PROMY],
        minzoom: 14,
        maxzoom: 16,
      })
      this.map.addLayer({
        id: this.source_and_layers_geo_parcelles[1],
        type: 'fill',
        'source-layer': 'parcelles',
        source: this.source_and_layers_geo_parcelles[0],
        paint: {
          'fill-opacity': 0.6,
          'fill-color': '#8F6DB0',
        },
      })
      this.map.addLayer({
        id: this.source_and_layers_geo_parcelles[2],
        type: 'line',
        'source-layer': 'parcelles',
        source: this.source_and_layers_geo_parcelles[0],
        layout: {},
        paint: {
          'line-color': '#3a2b53',
          'line-width': 1,
        },
      })
    },
    getParcellePrincipalThroughCenterOfAllParcelles(parcelles) {
      if (!parcelles.length) return null
      let center = this.$turf.pointOnFeature(
        this.$turf.featureCollection(parcelles),
      )
      return parcelles.find((parcelle) =>
        this.$turf.booleanPointInPolygon(center, parcelle),
      )
    },
  },
}
